import s from '../CartItem/CartItem.module.css';
import Image from 'next/image';
import Link from 'next/link';
import {useEffect, useState} from 'react';
import {TrashCan} from '@components/widgets/Icons';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {TwoPrices} from '@components/cart/CartItem/TwoPrices';
import {useCartUI} from '@components/cart/CartSideCartUiProvider';
import {DisplayChildProductsForBundle} from '@components/product/global/BundleHelpers';
import {QuantitySelector} from '@components/widgets/QuantitySelector/QuantitySelector';

const placeholderImg = '/product-img-placeholder.svg';

export const CartSidebarMobileCartItem = ({item, isCompatible, updating, setUpdating}) => {
  // console.log('[CartSidebarMobileCartItem][Item] item:', item);
  // console.log('[CartSidebarMobileCartItem][Item] isCompatible:', isCompatible);

  const [quantity, setQuantity] = useState(item?.quantity);

  const {closeCart} = useCartUI();
  const {isDealer, updateLocalItem, deleteLocalItem} = useDatabaseCartProviderV3();

  const sku = item?.sku;
  const cartTitle = item?.short_title ?? item?.sku;
  const options = item?.options;

  const productTypeIsBundle = item?.product_type === 'bundle';

  const childProducts = item?.child_products;

  const preorderInfo = item?.variant?.preorderInfo;
  const isPreorderItem = preorderInfo?.is_pre_order_eligible && preorderInfo?.is_pre_order_enabled;
  const shouldSendAsPreOrderedItem = !item?.variant?.currentlyInStock && isPreorderItem;

  const closeSidebarIfPresent = () => {
    setTimeout(closeCart, 300);
  };

  const deleteCartItem = async (sku) => {
    setUpdating(true);
    await deleteLocalItem(sku);
    setUpdating(false);
  };

  const handleChange = async (value) => {
    setUpdating(true);
    setQuantity(value);
    await updateLocalItem({
      sku: item.sku,
      quantity: value
    });
    setUpdating(false);
  };

  const handleCartItemClick = () => {
    closeSidebarIfPresent();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (item?.quantity !== quantity) {
      setQuantity(item?.quantity);
    }
  }, [item.quantity]);

  const categoryIsLoadedVsEnclosure = item?.variant?.category === 'loaded-subwoofer-enclosures';
  const categoryIsSubwoofers = item?.variant?.category === 'subwoofers';

  let sideBarItemsClassNames = 'relative flex flex-row space-x-1 py-1 mr-1';
  let incompatibleModelText;
  if (!isCompatible && categoryIsSubwoofers) {
    sideBarItemsClassNames = `${sideBarItemsClassNames} border-amber-400 border-2`;
    incompatibleModelText =
      'This subwoofer is not compatible with one or more enclosures in your cart.';
  }

  return (
    <div style={{opacity: updating ? '0.5' : '1', pointerEvents: updating ? 'none' : 'auto'}}>
      <div className="py-2">
        <div className={sideBarItemsClassNames}>
          <div className="w-20 h-20 relative overflow-hidden cursor-pointer z-0 border rounded">
            <Link href={`/products/${item?.path}`} onClick={handleCartItemClick}>
              <Image
                onClick={closeSidebarIfPresent}
                className={s?.productImage}
                width={170}
                height={170}
                src={item?.variant?.image?.url || placeholderImg}
                alt={item?.variant?.image?.altText || 'Product Image'}
              />
            </Link>
          </div>

          <div className="flex-1 flex flex-col justify-between items-start text-base pr-2">
            <div>
              <Link href={`/products/${item?.path}`} onClick={handleCartItemClick}>
                <div
                  style={{
                    cursor: 'pointer',
                    color: 'rgb(15,40,61)',
                    fontWeight: '500',
                    fontSize: '15px',
                    paddingRight: '20px'
                  }}
                  onClick={() => closeSidebarIfPresent()}>
                  {formatCartTitle(cartTitle)}
                </div>
              </Link>

              <DeleteCartItemSidebarCart sku={sku} handleDeleteCartItem={deleteCartItem} />
            </div>

            {options && options?.length > 0 && (
              <div className="flex items-center pb-1 pt-1">
                {options?.map((option, index) => {
                  return (
                    <div
                      key={`${item.id}-${option.name}`}
                      className="text-xs text-gray-600 inline-flex items-center justify-center">
                      <span className="">Item: {option?.value}</span>
                      {index === options?.length - 1 ? '' : <span className="mr-3" />}
                    </div>
                  );
                })}
              </div>
            )}

            <DisplayChildProductsForBundle childProducts={childProducts} />

            <TwoPrices item={item} isDealer={isDealer} />

            {shouldSendAsPreOrderedItem && (
              <span
                className="border rounded rounded-2 text-xs pl-2 pr-2 border-gray-800"
                style={{
                  backgroundColor: 'rgb(255,251,235)'
                }}>
                Pre-Order Item
              </span>
            )}

            {incompatibleModelText && (
              <div>
                <span className="text-amber-600 text-xs">
                  <strong>Warning:&nbsp;</strong>
                  {incompatibleModelText}
                </span>
              </div>
            )}

            <QuantitySelector
              value={quantity}
              handleChange={handleChange}
              size={4}
              textSize="text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const DeleteCartItemSidebarCart = ({sku, handleDeleteCartItem}) => {
  return (
    <button
      onClick={async () => {
        await handleDeleteCartItem(sku);
      }}
      aria-label="CloseXButton"
      className="transition ease-in-out duration-150 flex items-center
     focus:outline-none absolute top-2 right-2">
      <TrashCan color={'rgb(90, 94, 96)'} className="h-4 w-4" />
    </button>
  );
};

const formatCartTitle = (title) => {
  if (title?.startsWith('New Year Sale Daily Deal')) {
    const dailyDealText = 'New Year Sale Daily Deal';
    return (
      <span style={{fontSize: '14.9px', display: 'inline-block', maxWidth: '100%'}}>
        <span style={{fontWeight: 'bold', color: 'black'}}>{dailyDealText}</span>
        <span
          style={{
            display: 'inline-block',
            wordBreak: 'break-word',
            maxWidth: 'calc(100% - length_of_cyber_monday_deal_text)'
          }}>
          {title?.slice(dailyDealText?.length)}
        </span>
      </span>
    );
  } else if (title?.startsWith('New Year Sale')) {
    return (
      <span style={{fontSize: '15px'}}>
        <span style={{fontWeight: 'bold', color: 'black'}}>New Year Sale</span>
        <span style={{display: 'inline-block', wordBreak: 'break-word'}}>
          {title?.slice('New year Sale'?.length)}
        </span>
      </span>
    );
  } else {
    return <span>{title}</span>;
  }
};
