import SkarAudioWhiteLogoMedium from '@image/logos/skar-audio-logo-white-medium.webp';
import Image from 'next/image';
import Link from 'next/link';
import {defaultThemeImagePaths, siteSettingsV2} from '@helpers/config-master.mjs';
import cn from 'clsx';
import CartToggle from '../cart/CartToggle';
import DesktopNavigationMenu from './menu-desktop/NavigationMenu';
import PreHeaderBar from './menu-desktop/DesktopPreHeaderBar.jsx';
import {useState} from 'react';
import {
  MobileMenuHeaderLinksCollections,
  MobileNavigationMenu
} from './menu-mobile/MobileNavigation';
import {useUI} from '@components/widgets/UiProvider';
import {useCartUI} from '@components/cart/CartSideCartUiProvider';
import {SearchIcon} from '@components/widgets/Ui';

export const Header = ({
  storeName,
  menu,
  isMobile,
  showPreHeader,
  disableMobileNav,
  showNav,
  showCartIcon
}) => {
  const {displayMobileNav, openMobileNav, closeMobileNav} = useUI();
  const {closeCart} = useCartUI() ?? {};

  const [scrollbarCollection] = useState(0);

  const open = (focusSearch = false) => {
    openMobileNav(focusSearch);

    if (isMobile === true) {
      document.body.classList.add('no-scroll');
      document.documentElement.classList.add('no-scroll');
      closeCart();
    } else {
      document.body.classList.remove('no-scroll');
      document.documentElement.classList.remove('no-scroll');
    }
  };

  const close = () => {
    closeMobileNav();
    document.body.classList.remove('no-scroll');
    document.documentElement.classList.remove('no-scroll');
  };

  return (
    <>
      <div className={'block lg:hidden'}>
        <MobileHeader
          isMobileNavOpen={displayMobileNav}
          setIsMobileNavOpen={(x) => (x ? open() : close())}
          openMobileSearch={() => open(true)}
          menu={menu}
          disableMobileNav={disableMobileNav}
          showNav={showNav}
          scrollbarCollection={scrollbarCollection}
          showCartIcon={showCartIcon}
        />
      </div>
      <div className={'hidden lg:block'}>
        <DesktopHeader
          showPreHeader={showPreHeader}
          storeName={storeName}
          menu={menu}
          showNav={showNav}
        />
      </div>
    </>
  );
};

// DesktopHeader @ADA via ChatGPT ✔️
const DesktopHeader = ({showPreHeader, storeName, menu, showNav}) => {
  return (
    <header
      className={cn(
        'sticky z-20 w-full-screen h-42 mx-auto bg-opacity-95 bg-menu',
        showPreHeader ? 'md:h-[170px] 2xl:h-[140px]' : ''
      )}
      id='header'
      aria-label='Main Desktop Header'>
      {showPreHeader ? <PreHeaderBar /> : null}

      <div className='px-3 py-4 h-max[120px]'>
        <DesktopNavigationMenu storeName={storeName} menu={menu} showNav={showNav} />
      </div>
    </header>
  );
};

// MobileHeader @ADA via ChatGPT ✔️
const MobileHeader = ({
  isMobileNavOpen,
  setIsMobileNavOpen,
  openMobileSearch,
  menu,
  disableMobileNav,
  showNav,
  scrollbarCollection,
  showCartIcon
}) => {
  const hideMobileNav = !(showNav === false || disableMobileNav === true);

  const closeMobileNav = () => {
    if (isMobileNavOpen) setIsMobileNavOpen(false);
  };

  const activeSale = siteSettingsV2.getSaleSettings();
  const showHeaderTextOverlay = activeSale?.layoutSettings?.layout?.mobile?.showHeaderTextOverlay;
  const headerTextOverlayText = activeSale?.layoutSettings?.layout?.mobile?.HeaderTextOverlayText;

  return (
    <header
      id='mobile-header'
      className='sticky top-0 z-20 w-full-screen text-gray-300'
      aria-label='Main Mobile Header'>
      <div className='h-[50px] mx-auto bg-header'>
        <div className='h-full w-full'>
          <div className='w-full h-full flex justify-between items-center'>
            <div className='relative z-10 pl-4'>
              <MobileNavigationMenu
                isOpen={isMobileNavOpen}
                setIsOpen={setIsMobileNavOpen}
                menu={menu}
              />
            </div>

            <div
              className={`flex justify-center flex-1 relative w-full z-5 ${showCartIcon ? 'translate-x-[18px]' : 'translate-x-[24px]'}`}>
              <Link href='/' onClick={closeMobileNav}>
                <div className='w-[80px] xs:w-[120px] translate-y-1 relative'>
                  <Image
                    alt='Skar Audio Logo'
                    src={SkarAudioWhiteLogoMedium}
                    className='object-contain'
                    loading='eager'
                  />

                  {/*Inline styled special header overlay text */}
                  {showHeaderTextOverlay && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        left: '118px',
                        color: '#fff',
                        fontSize: '15.5px',
                        fontFamily: '"Bebas Neue", sans-serif',
                        fontWeight: '600',
                        whiteSpace: 'nowrap',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)',
                        WebkitTextStroke: '0.5px rgba(0, 0, 0, 0.5)',
                        padding: '1.5px 3.5px',
                        background: 'linear-gradient(45deg, #00BFFF, #1E90FF)',
                        borderRadius: '3px',
                        transition: 'transform 0.3s ease',
                        transform: 'rotate(-12deg) translateY(-5px)'
                      }}
                    >
                      {headerTextOverlayText}
                    </div>
                  )}


                  {/*<div*/}
                  {/*  style={{*/}
                  {/*    position: 'absolute',*/}
                  {/*    top: '40px',*/}
                  {/*    left: '85px',*/}
                  {/*    color: 'yellow',*/}
                  {/*    fontSize: '14px',*/}
                  {/*    fontFamily: 'sans-serif',*/}
                  {/*    transform: 'translate(95%, -110%) rotate(-5deg)',*/}
                  {/*    fontWeight: '700',*/}
                  {/*    whiteSpace: 'nowrap'*/}
                  {/*  }}>*/}
                  {/*  Extended*/}
                  {/*</div>*/}
                </div>
              </Link>
            </div>

            <div className='flex relative z-10 pr-4 '>
              {showCartIcon ? (
                <div className={'flex justify-end gap-1'}>
                  <button
                    onClick={() => {
                      openMobileSearch(true);
                    }}
                    className='w-8 text-white'
                    aria-label='Open search'>
                    <SearchIcon />
                  </button>

                  <div className='flex justify-end items-center w-8'>
                    <CartToggle
                      handleClick={() => {
                        closeMobileNav();
                      }}
                      aria-label='Open shopping cart'
                    />
                  </div>
                </div>
              ) : (
                <div className='w-20' />
              )}
            </div>
          </div>
        </div>
      </div>

      {hideMobileNav && (
        <>
          <div className='h-[40px] relative w-full bg-secondary items-center overflow-y-hidden'>
            <MobileMenuHeaderLinksCollections />
            <GradientBackground
              direction='left'
              scrollbarCollection={scrollbarCollection}
              scrollEnd={0}
            />
            {/* <GradientBackground direction='right' scrollbarCollection={scrollbarCollection} scrollEnd={3} /> */}
          </div>
        </>
      )}
    </header>
  );
};

export const MenuLink = ({item}) => (
  <div style={{height: 40, width: item?.width}} key={item?.path}>
    <Link
      className='sm:mx-6 hover:text-gray-300 uppercase whitespace-nowrap font-bebas text-white text-2xl custom-margin-top'
      href={item?.path}
      key={item?.title}
      aria-label={item?.label ?? item?.title}>
      {item?.title}
    </Link>
  </div>
);

export const GradientBackground = ({direction, scrollbarCollection, scrollEnd}) => {
  return (
    <div
      className={`h-full w-10 absolute top-0 custom-transition ${
        direction === 'left' ? 'left-0' : 'right-0'
      }`}
      style={{
        opacity: scrollbarCollection !== scrollEnd ? 1 : 0,
        background: `linear-gradient(${
          direction === 'left' ? '90deg' : '270deg'
        }, rgb(72 75 76) 8.6%, rgba(255, 253, 253, 0) 100%)`
      }}
    />
  );
};

export const CollectionPageHeaderBannerMobile = ({saleIsEnabled, isDailyDealCollection}) => {
  const mobileCollectionHeaderImage = isDailyDealCollection
    ? defaultThemeImagePaths?.mobileCollectionPageHeaderImageDailyDeal
    : defaultThemeImagePaths?.mobileCollectionPageHeaderImage;

  return (
    saleIsEnabled && (
      <div className='m-auto -mb-2'>
        {mobileCollectionHeaderImage && (
          <Image
            src={mobileCollectionHeaderImage}
            alt='discount banner'
            width='600'
            height='160'
            style={{paddingTop: 2}}
            sizes={'100vw'}
          />
        )}
      </div>
    )
  );
};
