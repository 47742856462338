import Head from 'next/head';
import {
  baseUrl,
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';
import {convertJsDateToShortDate} from '../../../site/helpers/common/date.mjs';

export const CustomProductPageSEO = ({props}) => {
  // console.log('[CustomProductPageSEO]', props);
  const parentVariant = props?.normalizedProducts?.parentVariant;
  const childVariants = props?.normalizedProducts?.childVariants;
  // console.log({parentVariant, childVariants});

  const parentSku = parentVariant?.sku;
  const seoTitle = parentVariant?.seoTitle;
  let productPath = parentVariant?.productPath;
  if (!productPath?.includes('/products/')) {
    productPath = `/products/${productPath}`;
  }
  const canonicalUrl = `${baseUrl}/${productPath}`;

  const imageUrl = parentVariant?.parentMainImage;
  // console.log('[imageUrl]:', imageUrl);

  const productKeywords = parentVariant?.parentProductKeywords;
  const seoDescription = parentVariant?.seoDescription;

  const breadcrumbListJsonLd = generateCustomProductPageBreadcrumbsJsonLD(parentVariant);
  // console.dir({breadcrumbListJsonLd}, {depth: null})

  const childVariantsAvailability = !!(childVariants && childVariants?.some((item) => item?.isInStock === true));

  const availability = childVariantsAvailability ? 'in stock' : 'out of stock';

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta charSet='UTF-8'/>
      <meta name='keywords' content={productKeywords}/>
      <meta name='description' content={seoDescription}/>
      <link rel='canonical' href={canonicalUrl}/>

      <GenerateStaticPageMetaData/>
      <GenerateOpenGraphPageMetaData
        title={seoTitle}
        url={canonicalUrl}
        description={seoDescription}
        image={imageUrl}
        type={'og:product'}
      />

      <meta property='product:retailer_item_id' content={parentSku}/>
      <meta property='product:availability' content={availability}/>
      <meta property='product:mfr_part_no' content={parentSku}/>
      <meta property='product:condition' content='new'/>
      <meta property='product:price:currency' content='USD'/>
      <meta property='product:brand' content='Skar Audio'/>

      {imageUrl && <meta property='og:image' content={imageUrl}/>}
      {imageUrl && <meta property='og:image:secure_url' content={imageUrl}/>}
      {imageUrl && <meta property='og:image:alt' content={seoTitle}/>}

      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{__html: JSON.stringify(breadcrumbListJsonLd)}}
        key='product-breadcrumbs-jsonld'
      />
    </Head>
  );
};

export const generateCustomProductPageBreadcrumbsJsonLD = (parentVariant) => {
  if (!parentVariant) return null;
  const breadcrumbs = [
    {label: 'Home', link: '/'},
    {
      label: parentVariant?.breadCrumbLevel1Title,
      link: `/collections${parentVariant?.breadCrumbLevel1Path}`
    },
    {
      label: parentVariant?.breadCrumbLevel2Title,
      link: parentVariant?.breadCrumbLevel2Path
    },
    {
      label: parentVariant?.siteTitle
    }
  ];

  const breadcrumbList = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item?.label,
      ...(item?.link ? {item: `https://www.skaraudio.com${item?.link}`} : {})
    }))
  };

  return breadcrumbList;
};

export const generateAggregateOffersJsonLDForProductPage = (parentVariant, childVariants) => {
  // Determine if both reviewCount and ratingValue are available
  const hasAggregateRating =
    parentVariant?.parentReviewCount && parentVariant?.parentReviewAvgRating;
  const futureDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 5);
  const priceValidUntil = convertJsDateToShortDate(futureDate);

  // Initialize jsonLd object
  const jsonLd = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: parentVariant?.seoTitle,
    image: parentVariant?.parentMainImage,
    description: parentVariant?.seoDescription,
    sku: parentVariant?.sku,
    brand: {
      '@type': 'Brand',
      name: 'Skar Audio'
    },
    offers: childVariants?.map((variant) => {
      const sku = variant?.sku;
      const upc = variant?.upc;
      const gtin12 = upc;
      const gtin14 = `00${upc}`;

      return {
        '@type': 'Offer',
        sku: sku,
        name: variant?.productTitle,
        url: variant?.variantHandle ?? null,
        gtin: upc,
        gtin12: gtin12,
        gtin14: gtin14,
        price: variant?.price ?? variant?.priceMap,
        priceCurrency: 'USD',
        priceValidUntil: priceValidUntil,
        availability: variant?.isInStock
          ? 'http://schema.org/InStock'
          : 'http://schema.org/OutOfStock',
        // itemOffered: {
        //   "@type": 'Product',
        //   name: variant?.productTitle,
        //   sku: variant?.sku,
        //   model: variant?.sku,
        // },
        hasMerchantReturnPolicy: {
          '@type': 'MerchantReturnPolicy',
          applicableCountry: 'US',
          returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
          merchantReturnDays: 30,
          returnMethod: 'https://schema.org/ReturnByMail',
          returnFees: 'https://schema.org/FreeReturn'
        },
        shippingDetails: {
          '@type': 'OfferShippingDetails',
          shippingRate: {
            '@type': 'MonetaryAmount',
            value: '0',
            currency: 'USD'
          },
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: 'US'
          },
          deliveryTime: {
            '@type': 'ShippingDeliveryTime',
            handlingTime: {
              '@type': 'QuantitativeValue',
              minValue: 0,
              maxValue: 1,
              unitCode: 'DAY'
            },
            transitTime: {
              '@type': 'QuantitativeValue',
              minValue: 1,
              maxValue: 3,
              unitCode: 'DAY'
            }
          }
        }
      };
    })
  };

  // Conditionally add the aggregateRating object if both reviewCount and ratingValue exist
  if (hasAggregateRating) {
    jsonLd.aggregateRating = {
      '@type': 'AggregateRating',
      reviewCount: parentVariant?.parentReviewCount,
      ratingValue: parentVariant?.parentReviewAvgRating
    };
  }

  return jsonLd;
};

// export const JsonLdCustomProductBreadcrumbs = ({
//                                                  handle,
//                                                  title,
//                                                  handle2,
//                                                  title2,
//                                                  handle3,
//                                                  title3
//                                                }) => {
//   const baseHref = "https://www.skaraudio.com"

//   const json = {
//     '@context': 'https://schema.org',
//     '@type': 'BreadcrumbList',
//     itemListElement: [
//       {
//         '@type': 'ListItem',
//         position: 1,
//         item: {
//           '@id': baseHref,
//           name: 'Home'
//         }
//       },
//       {
//         '@type': 'ListItem',
//         position: 2,
//         item: {
//           '@id': baseHref + handle,
//           name: title
//         }
//       }
//     ]
//   };

//   if (handle2 && title2) {
//     json.itemListElement.push({
//       '@type': 'ListItem',
//       position: 3,
//       item: {
//         '@id': baseHref + handle2,
//         name: title2
//       }
//     });
//   }

//   if (handle3 && title3) {
//     json.itemListElement.push({
//       '@type': 'ListItem',
//       position: 4,
//       item: {
//         '@id': baseHref + handle3,
//         name: title3
//       }
//     });
//   }

//   return <script
//     type='application/ld+json'
//     dangerouslySetInnerHTML={{__html: JSON.stringify(json)}}
//   />;
// }
