import cn from 'clsx';
import Image from 'next/image';
import {useIsMobile} from '@helpers/hooks/useWindow';

export const CartItemImage = ({sku, mainImage, quantity}) => {
  // console.log("[CartItemImage] sku: ", sku, " || mainImage: ", mainImage, " || quantity: ", quantity);
  const placeholderImg = '/product-img-placeholder.svg';

  const isMobile = useIsMobile();
  const imageWidth = isMobile ? 64 : 80;

  return (
    <div className='relative flex-shrink-0'>
      <div
        className={cn('border rounded-md border-gray-300')}
        style={{
          lineHeight: 0,
          fontSize: 0,
          width: `${imageWidth}`,
          height: `${imageWidth}`
        }}>
        <Image
          src={mainImage ?? placeholderImg}
          width={imageWidth}
          height={imageWidth}
          className='rounded-md'
          alt={'Skar Audio ' + sku + ' Cart Image' ?? 'Skar Audio Product Image in Cart'}
          style={{
            verticalAlign: 'bottom',
            objectFit: 'contain'
          }}
        />
      </div>
      <div
        className='absolute top-0 right-0 bg-gray-500 rounded-full
            w-5 h-5 text-center text-white -m-2 text-xs pt-0.5'>
        {quantity}
      </div>
    </div>
  );
}
