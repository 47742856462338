import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import LogoBlack from '@image/logos/skar-audio-logo-black.svg';
import {ErrorBox, MessageBox} from '@components/form-fields/MessageBoxes.jsx';
import {Field} from '@components/form-fields/Field.jsx';
import {TextInput} from '@components/form-fields/Inputs/TextInput.jsx';
import {SubmitButton} from '@components/form-fields/Inputs/Button.jsx';

export const MobilePopup = ({
  handleSubmit,
  submitForm,
  success,
  existsMessage,
  errors,
  register,
  isSubmitting,
  formError,
  isDisabled,
  email
}) => {
  const hasSomeValueAndIsInvalid = formError && !errors?.email;
  const showNewSuccessMessage = !existsMessage && success;

  const hideInputForEmail = showNewSuccessMessage;

  if (isSubmitting || success) {
    isDisabled = true;
  }

  return (
    <div className="pt-0 text-center text-black">
      <div className="flex justify-center my-1 mb-1">
        <div className="relative w-48">
          <Image src={LogoBlack} alt="Skar Audio Logo Black" width={192} height={60} />
        </div>
      </div>
      <div className="uppercase text-xl font-bold -mt-1 -mb-1">Join Now</div>
      <div className="uppercase font-oswald text-orange-700 font-bold" style={{fontSize: '32pt'}}>
        V.I.P
      </div>

      <div className="uppercase text-l">Exclusive Invite</div>
      <div className="pt-2 pb-1">Join our VIP list to unlock special discounts and more!</div>

      {existsMessage && <MessageBox>{existsMessage}</MessageBox>}

      {hasSomeValueAndIsInvalid && <ErrorBox>{formError}</ErrorBox>}

      {showNewSuccessMessage && (
        <MessageBox>
          <span style={{fontSize: '16px'}}>
            {"Thanks for subscribing, you're now a Skar Audio VIP!"}
          </span>
        </MessageBox>
      )}

      {!hideInputForEmail && (
        <form onSubmit={handleSubmit(submitForm)}>
          <Field error={errors?.email}>
            <TextInput
              name={'email'}
              className="rounded-xl py-3"
              register={register}
              validations={{
                required: 'Your email address is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'invalid email address'
                }
              }}
              placeholder={`Email Address`}
            />
          </Field>

          {errors?.email && <p>{errors?.email?.message}</p>}

          <div className="flex justify-center mb-3">
            <SubmitButton
              onClick={submitForm}
              submitting={isSubmitting}
              disabled={!email || isDisabled}
              fullWidth>
              Submit
            </SubmitButton>
          </div>

          {formError && <ShowPopUpError>{formError}</ShowPopUpError>}

          <div
            style={{
              fontSize: '7pt',
              marginBottom: '4px',
              marginTop: '4px'
            }}>
            By clicking submit you are agreeing to sign up to receive emails from Skar Audio about
            special offers, products, and services. See our
            <Link href="/pages/privacy-policy">privacy policy</Link> for more details.
          </div>
        </form>
      )}
    </div>
  );
};

const ShowPopUpError = ({children}) => {
  return <div className="bg-rose-100 rounded border border-rose-400 mt-1 mb-1 p-1">{children}</div>;
};
