import {Layout} from '@components/layout/index';
// import {BFCM_HomePage} from "@components/home/BFCMHomePage";
import {HomePage} from "@components/home/MainHomePage";

export default function Home() {
  const pagePropsNormalHomePage = {
    showFooterSignup: false,
    paddingX: false
  };

  const pagePropsSaleHomePage = {
    showCountdownTimer: true,
    showCountdownTimerMobile: true,
    showGeoTransitBanner: true,
    showFooterSignup: false,
    showFooterSignupMobile: false,
    showEmailSubscriberPopup: false,
    showPushNotificationPopup: false,
    disableMobileNav: false,
    paddingX: false
  };


  return (
    // >> Update {pageProps} me by theme <<
    <Layout pageProps={pagePropsSaleHomePage}>
      <h1 aria-label='Skar Audio Home Page'></h1>

      <HomePage/>

      {/*<BFCM_HomePage/>*/}
    </Layout>
  );
}

