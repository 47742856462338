import {getMenuCombined} from '../../helpers/get-menu.mjs'
import {arraySort} from '../../helpers/common/array.mjs'


export function getCompatibilityMapping(variant) {
  // console.log("[getCompatibilityMapping]:", {variant})
  const metafields = variant?.metafields;
  // console.log({metafields})
  if (!Array.isArray(metafields)) {
    return null;
  }
  const boxInfo = metafields?.find(x => x?.key === 'tab_box_info');
  if (!boxInfo?.value || !Array.isArray(boxInfo?.value?.boxInfo)) {
    return null;
  }
  const vsCompatibility = boxInfo?.value?.boxInfo?.[0];
  if (!vsCompatibility || vsCompatibility?.title !== 'Compatibility Chart') {
    return null;
  }
  // console.log({metafields, vsCompatibility})
  return vsCompatibility?.table ?? {};
}

export function getSubwooferCompatibilityChecksForEnclosures(lineItems = []) {
  // console.log("[getSubwooferCompatibilityChecksForEnclosures]:", lineItems);

  const hasAnyLoadedVsEnclosures = lineItems?.some((lineItem) => {
    const sku = lineItem?.sku;
    const skuIsNotVs10or12 = !(sku?.startsWith('VS-10') || sku?.startsWith('VS-12'));
    const skuIsLoadedVsEnclosure = skuIsNotVs10or12 && sku?.startsWith("L-");
    return skuIsLoadedVsEnclosure;
  })
  if (hasAnyLoadedVsEnclosures) return;

  const hasAnyVehicleSpecific = lineItems?.some((lineItem) => {
    const sku = lineItem?.sku;
    const skuIsNotVs10or12 = !(sku?.startsWith('VS-10') || sku?.startsWith('VS-12'));
    const skuIsVsEmptyEnclosure = skuIsNotVs10or12 && sku?.startsWith("VS-") && !sku?.includes("L-");
    return skuIsVsEmptyEnclosure;
  })

  let subwoofers = lineItems?.filter((x) => x?.variant?.category === 'subwoofers')
  if (!subwoofers?.length) {
    return {
      message: 'Would you like to add some subwoofers to the cart as well?',
      linkText: 'Subwoofers',
      link: '/collections/car-subwoofers',
    }
  }

  if (!hasAnyVehicleSpecific) return null;

  let vehicleSpecificItems = lineItems?.filter((lineItem) => {
    const sku = lineItem?.sku;
    const skuIsNotVs10or12 = !(sku?.startsWith('VS-10') || sku?.startsWith('VS-12'));
    const skuIsVehicleSpecificEmptyEnclosure = skuIsNotVs10or12 && sku.startsWith("VS-");
    return skuIsVehicleSpecificEmptyEnclosure;
  })

  let enclosuresWithSubEligibilityAttached = vehicleSpecificItems?.map((enclosure) => {
    // console.log("[enclosure]:", enclosure)
    const compatibleSubwooferSize = enclosure?.variant?.props?.['Subwoofer Size'] ?? enclosure?.variant?.props?.['Subwoofer Compatibility Size'];
    enclosure.compatibleSubwooferSize = compatibleSubwooferSize;

    const compatibilityChecks = subwoofers?.map((subwoofer) => {
      const subwooferSize = subwoofer?.variant?.props?.Size
      subwoofer.subwooferSize = subwooferSize;
      const isCompatible = isSubwooferCompatibleWithEnclosure(enclosure, subwoofer)
      const compatibilityResult = {
        enclosureSku: enclosure.sku,
        enclosureSubwooferSize: enclosure.compatibleSubwooferSize,
        subwooferSku: subwoofer.sku,
        subwooferSize: subwoofer.subwooferSize,
        compatible: isCompatible ?? false,
      }
      return compatibilityResult;
    })
    // console.log({compatibilityChecks})
    const suggestions = getEnclosureSuggestions(enclosure)
    const oneSet = {
      // enclosure,
      sku: enclosure.sku,
      product_id: enclosure.product_id,
      compatibilityChecks,
      suggestions,
    }
    return oneSet
  })
  // console.log({enclosuresWithSubEligibilityAttached})

  enclosuresWithSubEligibilityAttached = enclosuresWithSubEligibilityAttached?.map((set) => {
    // must check for false, not null!!!
    set.compatibilityChecks = set?.compatibilityChecks?.filter((x) => x?.compatible === false)
    return set
  })
  enclosuresWithSubEligibilityAttached = enclosuresWithSubEligibilityAttached?.filter((set) => {
    return set?.compatibilityChecks?.some((x) => x?.compatible === false)
  })

  if (!enclosuresWithSubEligibilityAttached?.length) {
    return null
  }
  return enclosuresWithSubEligibilityAttached;
}

export const isSubwooferCompatibleWithEnclosure = (enclosure, subwoofer) => {
  // console.log({enclosure, subwoofer})
  const compatMapping = getCompatibilityMapping(enclosure?.variant)
  const sku = subwoofer?.sku
  const skuSeries = sku?.split('-')?.[0]
  let compatValue = String(compatMapping?.[skuSeries])?.trim()
  if (!skuSeries) {
    return false
  }
  if (!compatValue) {
    return false
  }
  const isCompatible = compatValue === '✔' || compatValue === '✔️'

  const enclosureCompatSubSize = enclosure?.compatibleSubwooferSize ?? null;
  const subwooferSize = subwoofer?.subwooferSize ?? null;
  if (!enclosureCompatSubSize || !subwooferSize) {
    return false;
  }
  const areSameSize = compareSubSizesByStringValues(enclosureCompatSubSize, subwooferSize);
  if (!areSameSize) {
    return false;
  }
  // console.log({sku, skuSeries, compatValue, isCompatible})
  return isCompatible
}


export const getEnclosureSuggestions = (vsItem) => {
  // console.log({vsItem})
  const compatMapping = getCompatibilityMapping(vsItem?.variant) || {}
  const listSeries = Object.entries(compatMapping).filter((x) => {
    const value = String(x?.[1])?.trim()
    return value === '✔' || value === '✔️'
  }).map((x) => x?.[0]) // keys
  // console.log('✔ ', vsItem.sku, 'is compatible with', listSeries);
  const menu = getMenuCombined()
  const subwoofers = menu?.find((x) => x?.handle === 'car-subwoofers')
  if (!subwoofers || !subwoofers?.collections?.length) {
    return null
  }

  let matchingMenuItems = subwoofers?.collections?.filter((x) => {
    let sMenuSeries = x?.title?.split(' ')?.[0]
    return listSeries?.includes(sMenuSeries)
  })

  matchingMenuItems = matchingMenuItems.map((category) => {
    let categoryProducts = category?.products?.map((x) => {
      let properSku = x.sku // VD-10 (D2/D4)
      let inBrackets = properSku.match(/\(([^)]+)\)/)?.[1]
      let options = inBrackets?.split('/')
      let properSkuList = options.map((x) => properSku.replace(/\(([^)]+)\)/, x))
      return {
        ...x, properSkuList,
      }
    })
    return {
      ...category, products: categoryProducts,
    }
  })
  return matchingMenuItems
}


export function getOnlyIncompatibleSkuList(incompatibleItems) {
  if (!incompatibleItems?.length) {
    return null;
  }
  const compatibilityChecks = incompatibleItems?.flatMap(x => x?.compatibilityChecks);
  const onlyIncompatibleLines = compatibilityChecks?.filter(x => x?.compatible === false)
  const onlyIncompatibleSubwoofers = onlyIncompatibleLines?.map(x => x?.subwooferSku);
  // console.log({onlyIncompatibleSubwoofers})
  return onlyIncompatibleSubwoofers;
}

export function pushIncompatibleDown(lineItems = [], incompatibleSkuList = []) {
  // console.log("[pushIncompatibleDown]:", {lineItems})
  lineItems = lineItems.map((item) => {
    const compValue = incompatibleSkuList?.find((x) => x === item.sku)
    const isCompatible = !compValue
    return {
      ...item,
      isCompatible,
    }
  })
  arraySort(lineItems, (x) => (x?.isCompatible ? 0 : 1))
  return lineItems
}

export function pushPreorderDown(lineItems = []) {
  let lineItems2 = [...lineItems];
  arraySort(lineItems2, x => x?.is_pre_ordered_item ? 1 : 0);
  return lineItems2;
}


export const compareSubSizesByStringValues = (str1, str2) => {
  // Use regular expression to remove special characters
  const regex = /[^\w\s]|_/g;
  let cleanedStr1 = str1.replace(regex, "").toLowerCase();
  let cleanedStr2 = str2.replace(regex, "").toLowerCase();

  return cleanedStr1 === cleanedStr2;
}
