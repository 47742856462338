import {mutate} from 'swr';
import {useState} from 'react';
import {consoleLogLogout} from '@helpers/logging/provider-logging.mjs';
import {deleteCartAndCheckoutTokensOnClientAndServer} from '@components/widgets/Cookies';
import {signIn, signOut} from 'next-auth/react';

export const useLogoutV2 = () => {
  const [isLoggingOut, setLoggingOut] = useState(false);

  const onLogout = async (options = {}) => {
    try {
      consoleLogLogout(` > Logging Out.. Resetting cart & checkout token values..`);
      const redirectOnLogout = options?.redirectOnLogout ?? false;
      await signOut({redirect: redirectOnLogout});
      await deleteCartAndCheckoutTokensOnClientAndServer();
      return {
        success: 'true'
      };
    } catch (e) {
      setLoggingOut(false);
      console.error('Error during logout: ', e?.message);
      return {
        success: 'false'
      };
    }
  };

  const logout = async (options = {}) => {
    setLoggingOut(true);
    await onLogout(options);
    consoleLogLogout('  > Logging Out - Logout Complete');
    await mutate(`/api/cartv2`);
  };

  return {
    logoutV2: logout,
    isLoggingOut
  };
};

export const useLoginV2 = () => {
  const loginV2 = async ({
                           email,
                           password,
                           redirect = false,
                           callbackUrl = null,
                           lineItems
                         }, postData) => {
    // console.log("[Account Provider][signInV2] Called: ", {
    //   email,
    //   password,
    //   redirect,
    //   callbackUrl,
    //   lineItems
    // });

    const options = {
      redirect: redirect ?? false,
      email: email,
      password: password,
      callbackUrl: !callbackUrl || callbackUrl === 'null' ? undefined : callbackUrl,
      json: true,
      cartLineItems: lineItems?.length > 0 ? JSON.stringify(lineItems) : null
    };
    // console.log("[Account Provider][signInV2] options: ", {options});
    const response = await signIn('credentials', options, postData);
    // console.log("[Account Provider][signInV2] Response: ", {response});

    if (response?.ok) {
      return {
        success: true,
        redirect: response?.url || '/account'
      };
    } else {
      return {
        success: false,
        reason: response?.error,
        redirect: false
      };
    }
  };

  return {
    loginV2
  };
};
