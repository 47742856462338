import styles from './review-photos.module.css';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import DelayComponentVisible from '@components/widgets/DelayComponentVisible';
import {useEffect, useState} from 'react';
import {Spinner} from '@components/widgets/Common';
import {ReviewThumbsUp} from '../thumbs-up';
import {
  ReviewHeader,
  ReviewPagination,
  ReviewTextWithShowMoreButton
} from '@components/product/reviews/common/ui';

const ReviewAdminBar = dynamic(
  () =>
    import('@components/product/reviews/review-photos/review-admin-bar').then(
      (mod) => mod.ReviewAdminBar
    ),
  {
    ssr: false
  }
);

export default function ReviewIndividualV2({
                                             review,
                                             prevReview,
                                             nextReview,
                                             currentReviewImageIndex,
                                             isIndividual = false,
                                             showTitleWithReviewOnMobile = true,
                                             close,
                                             isLast,
                                             isFirst
                                           }) {
  const [currentSlide, setCurrentSlide] = useState(currentReviewImageIndex || 0);
  const [showMoreReviewText, setShowMoreReviewText] = useState(false);
  const [showOnlyReviewImages, setShowOnlyReviewImages] = useState(false);
  const [totalSlides, setTotalSlides] = useState(review?.images?.length || 0);

  useEffect(() => {
    setTotalSlides(review?.images?.length || 0);
  }, [review]);

  const reviewText = review?.body;
  const thumbsUpCount = review?.thumbs_up || 0;

  const handleToggleReviewText = (e) => {
    e.stopPropagation();
    setShowOnlyReviewImages(!showOnlyReviewImages);
  };

  return (
    <div
      className="h-full text-white md:text-black md:w-full flex flex-col md:flex-row justify-stretch gap-y-2 gap-x-3
       md:gap-x-5 overflow-y-auto md:pl-4 md:pr-2 lg:overflow-hidden">
      {close && <ReviewCloseButton close={close} />}

      <ReviewImagesSlider
        currentSlide={currentSlide}
        review={review}
        totalSlides={totalSlides}
        isIndividual={isIndividual}
        setCurrentSlide={setCurrentSlide}
        isFirst={isFirst}
        isLast={isLast}
        prevReview={prevReview}
        nextReview={nextReview}
        showOnlyReviewImages={showOnlyReviewImages}
      />

      <div
        onClick={handleToggleReviewText}
        className={clsx(
          'flex flex-col w-full h-full absolute md:relative z-20 md:w-4/12 px-4 pt-5 md:pt-4 shrink-0 ' +
          'lg:overflow-y-auto transition-all duration-200',
          {
            'fade-bg-black-to-transparent-mobile': !showMoreReviewText,
            'fade-bg-nearly-transparent-to-black-mobile': showMoreReviewText
          }
        )}>
        <div className="md:hidden w-full pb-3 relative z-30 top-0">
          <ReviewPagination currentSlide={currentSlide} totalSlides={totalSlides} />
        </div>

        <div
          className={`space-y-1 relative z-10 ${showOnlyReviewImages ? 'fade-out-mobile' : 'fade-in-opacity-mobile'}`}>
          <ReviewHeader review={review} showTitleWithReviewOnMobile={showTitleWithReviewOnMobile} />

          <ReviewTextWithShowMoreButton
            reviewText={reviewText}
            setShowMoreReviewText={setShowMoreReviewText}
            showMoreReviewText={showMoreReviewText}
          />
        </div>

        <div className="pt-2 pb-8 text-black hidden md:block">
          <ReviewThumbsUp reviewId={review?.id} thumbsUp={thumbsUpCount} />
        </div>

        <div className="md:hidden">
          <ReviewArrows
            review={review}
            isIndividual={isIndividual}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            isFirst={isFirst}
            isLast={isLast}
            prevReview={prevReview}
            nextReview={nextReview}
            fadeOut={showOnlyReviewImages || showMoreReviewText}
          />
        </div>
      </div>

      <ReviewAdminBar review={review} isInModal={true} />
    </div>
  );
}

const ReviewImagesSlider = ({
                              review,
                              currentSlide,
                              totalSlides,
                              isIndividual,
                              setCurrentSlide,
                              isFirst,
                              isLast,
                              prevReview,
                              nextReview,
                              showOnlyReviewImages
                            }) => {
  const [reviewImages, setReviewImages] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (review?.images) {
      setReviewImages(review?.images);
    }
  }, [review]);

  const handleContextMenu = (e, image) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = image;
    link.download = '';
    link.click();
  };

  return (
    <div
      className="bg-transparent md:bg-neutral-100 md:aspect-[0.71] md:w-8/12 absolute md:relative w-full h-full overflow-hidden md:rounded-md z-10">
      {!loading && (
        <>
          <div className="hidden md:block w-full relative z-30 top-4 px-4">
            <ReviewPagination currentSlide={currentSlide} totalSlides={totalSlides} />
          </div>

          <div className="hidden md:block">
            <ReviewArrows
              review={review}
              isIndividual={isIndividual}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              isFirst={isFirst}
              isLast={isLast}
              prevReview={prevReview}
              nextReview={nextReview}
              showOnlyReviewImages={showOnlyReviewImages}
            />
          </div>
        </>
      )}

      {loading && (
        <div
          className="absolute top-0 left-0 z-20 bg-black md:bg-white flex items-center justify-center w-full h-full">
          <DelayComponentVisible delay={400}>
            <Spinner color="text-white" size="48" />
          </DelayComponentVisible>
        </div>
      )}
      {reviewImages &&
        reviewImages.map((image, index) => {
          // console.log("[ReviewImagesSlider] image:", image);
          return (
            <div
              key={`review-image-${index}`}
              className={`review-photo w-full h-full flex-shrink-0 relative select-none ${index === currentSlide ? 'block' : 'hidden'}`}
              onContextMenu={(e) => handleContextMenu(e, image)}>
              <a
                href={image}
                download
                className="absolute left-12 right-20 top-0 bottom-0 z-10"
                style={{opacity: 0}}
                onClick={(e) => e.preventDefault()}>
                Download
              </a>
              <Image
                src={image}
                alt={'photo review'}
                className="md:rounded-md object-contain object-center snap-center w-full h-auto"
                fill
                sizes="640px"
                priority
                onLoad={(img) => {
                  setLoading(false);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

const ReviewCloseButton = ({close}) => {
  return (
    <div
      className="p-4 pt-2 pr-2 cursor-pointer absolute top-6 right-0 z-50 md:top-0"
      onClick={() => {
        close();
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={'h-7 w-7'}
        viewBox="0 0 20 20"
        fill="currentColor">
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

const ReviewArrows = ({
                        review,
                        isIndividual,
                        currentSlide,
                        setCurrentSlide,
                        isFirst,
                        isLast,
                        prevReview,
                        nextReview,
                        fadeOut = false
                      }) => {
  const reviewImages = review?.images;

  const isFirstImage = currentSlide === 0;
  const isLastImage = currentSlide === reviewImages?.length - 1;
  const isLastImageAndLastReview = isLast && isLastImage;

  const handleLeftArrowClick = (e) => {
    e.stopPropagation();

    if (currentSlide === 0 && prevReview) {
      prevReview();
      setCurrentSlide(0);
    } else {
      const newCurrentIndex = currentSlide - 1;
      setCurrentSlide(newCurrentIndex);
    }
  };

  const handleRightArrowClick = (e) => {
    e.stopPropagation();

    const totalSlides = review?.images?.length - 1;
    if (currentSlide === totalSlides) {
      if (!isLast) {
        nextReview();
        setCurrentSlide(0);
      }
    } else {
      const newCurrentIndex = currentSlide + 1;
      setCurrentSlide(newCurrentIndex);
    }
  };

  const handlePrevSlide = (e) => {
    e.stopPropagation();

    const newCurrentIndex = currentSlide - 1;
    setCurrentSlide(newCurrentIndex);
  };

  const handleNextSlide = (e) => {
    e.stopPropagation();

    const newCurrentIndex = currentSlide + 1;
    setCurrentSlide(newCurrentIndex);
  };
  return (
    <>
      {!isIndividual && (
        <>
          {!isFirst && <Arrow left onClick={handleLeftArrowClick} fadeOut={fadeOut} />}

          {!isLastImageAndLastReview && <Arrow onClick={handleRightArrowClick} fadeOut={fadeOut} />}
        </>
      )}

      {isIndividual && (
        <>
          {!isFirstImage && <Arrow left onClick={handlePrevSlide} fadeOut={fadeOut} />}

          {!isLastImage && <Arrow onClick={handleNextSlide} fadeOut={fadeOut} />}
        </>
      )}
    </>
  );
};

const Arrow = (props) => {
  const classes = clsx({
    [styles['arrow-wrapper']]: true,
    [styles['arrow-left']]: props?.left,
    [styles['arrow-left-v2']]: props?.left,
    [styles['arrow-right']]: !props?.left,
    [styles['arrow-right-v2']]: !props?.left,
    [styles['arrow-fade-out']]: props?.fadeOut,
    [styles['arrow-disabled']]: props?.disabled
  });

  return (
    <div className={classes} onClick={props?.onClick}>
      <div className={styles['arrow']}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          {props?.left && (
            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
          )}
          {!props?.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      </div>
    </div>
  );
};
