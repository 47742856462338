import {useEffect, useRef, useState} from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value ?? '');
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export function useCallOnceAfter(code, delay = 500) {
  const [timerId, setTimerId] = useState();

  useEffect(() => {
    timerId && clearTimeout(timerId);
    const handler = setTimeout(() => {
      code();
    }, delay);
    setTimerId(handler);

    return () => {
      clearTimeout(handler);
    };
  }, [code, delay]);

  return null;
}

export function useForceRefresh() {
  const [state, setState] = useState(0);

  const forceRefresh = () => setState(state + 1);

  return {
    refreshCount: state,
    forceRefresh
  };
}

export const useOnClickOutside = (callback) => {
  const ref = useRef(undefined);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

export const useScrollToTopOfComponent = () => {
  const componentRef = useRef(null);

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, []);

  return componentRef;
};
