import Image from 'next/image';
import {defaultThemeImagePaths, priceHelper} from '@helpers/config-master.mjs';
import {Spinner} from '@components/widgets/Common';
import {VehicleSelectorComponent} from '@components/vehicle-specific/v2/year-make-model-dropdowns';
import {DesktopGradientLine, MobileGradientLine} from '@components/widgets/Ui';
import {VehicleSpecificBreadcrumbs} from '@components/vehicle-specific/v2/breadcrumbs';
import {RenderVehicleSpecificProductsDesktop} from '@components/vehicle-specific/v2/desktop/render-vs-products-desktop';
import {RenderVehicleSpecificProductListMobile} from '@components/vehicle-specific/v2/mobile/render-vs-products-mobile';
import {vehicleSpecificFilterByCheckmarks} from '@components/vehicle-specific/v2/sidebar-filter';
import {SidebarFilterDesktop, TopFilterMobile} from '@components/collections/FilteringSidebar';

export const VehicleSpecificCollectionPageComponent = ({
  session,
  products,
  selections,
  checkedFilters,
  setCheckedFilters,
  handleFilterChange
}) => {
  // console.log('[VehicleSpecificCollectionPageComponent][session]:',  session);
  // console.log('[VehicleSpecificCollectionPageComponent][products]:', products);

  let filteredProducts = filterProducts(products, selections);
  const filteredProductsByYear = filterProductsByYear(products, selections?.year);

  const years = getYears(products);
  const makes = extractUniqueMakes(filteredProductsByYear);
  const carData = associateModelsToMakes(filteredProductsByYear);

  const make = selections?.make;

  const makeOptions = makes;
  const modelOptions = carData[make];

  filteredProducts = vehicleSpecificFilterByCheckmarks(filteredProducts, checkedFilters);

  const mobileHeaderImage = defaultThemeImagePaths?.mobileCollectionPageHeaderImage;

  if (!carData || !makes || !filteredProducts) {
    return <Spinner />;
  }

  let HeaderVehicleText = 'Vehicle';
  if (selections?.make) {
    HeaderVehicleText = selections?.make;
  }
  if (selections?.model) {
    HeaderVehicleText = HeaderVehicleText + ' ' + selections?.model;
  }

  const pageTitle = 'Vehicle Specific Subwoofer Enclosures';

  return (
    <>
      <div className={'hidden md:block'}>
        <div id="header" className="m-auto md:max-w-[1300px]">
          <VehicleSpecificBreadcrumbs selections={selections} />

          <div id="desktop-vs-collection-page-title" className="my-2">
            <h1 className="font-oswald uppercase text-3xl">{pageTitle}</h1>
            <DesktopGradientLine />
          </div>

          <div className="lg:flex align-items-start gap-3">
            <div className="w-full lg:w-3/12">
              <VehicleSelectorComponent
                years={years}
                selections={selections}
                modelOptions={modelOptions}
                makeOptions={makeOptions}
              />
              <SidebarFilterDesktop
                checkedFilters={checkedFilters}
                setCheckedFilters={setCheckedFilters}
                handleFilterChange={handleFilterChange}
              />
            </div>

            <RenderVehicleSpecificProductsDesktop products={filteredProducts} session={session} />
          </div>
        </div>
      </div>
      <div className={'md:hidden'}>
        <div className="m-auto max-w-[768px]">
          <VehicleSpecificBreadcrumbs selections={selections} />

          <div id="mobile-vs-collection-page-title" className="my-1">
            <h1 className="font-oswald uppercase text-xl">{pageTitle}</h1>
            <MobileGradientLine />
          </div>

          <div className="align-items-start gap-3">
            <VehicleSelectorComponent
              years={years}
              selections={selections}
              modelOptions={modelOptions}
              makeOptions={makeOptions}
            />

            <TopFilterMobile
              checkedFilters={checkedFilters}
              setCheckedFilters={setCheckedFilters}
              handleFilterChange={handleFilterChange}
            />
          </div>

          {priceHelper.saleEnabled === true && (
            <div className="m-auto pb-2">
              {mobileHeaderImage && (
                <Image
                  src={mobileHeaderImage}
                  alt="mobile banner"
                  width="600"
                  height="160"
                  loading="eager"
                />
              )}
            </div>
          )}

          <h4 className="font-montserrat-semi-bold text-[22px] mb-1 px-2">
            All {HeaderVehicleText} Enclosures
          </h4>

          <RenderVehicleSpecificProductListMobile products={filteredProducts} session={session} />
        </div>
      </div>
    </>
  );
};

export const filterProducts = (products, {year, make, model, type}) => {
  // console.log(
  //   `[filterProducts][selections] >>> Year: ${year} >> Make: ${make} > Model: ${model} > Type: ${type}`
  // );
  const filteredProducts = products?.filter((product) => {
    // console.log("[filterProducts][product] >>", product);
    if (product?.properties) {
      const productStartYear = parseInt(product.properties['Start Year'], 10);
      const productEndYear = parseInt(product.properties['End Year'], 10);
      const productMake = product?.properties['Make'];
      const productCategory = product?.category;

      let productModels = product.properties['Model'];
      productModels = productModels?.split('|'); // Adding conditional chaining here

      if (year && !(year >= productStartYear && year <= productEndYear)) {
        return false;
      }
      if (make && make !== productMake) {
        return false;
      }
      if (
        model &&
        productModels &&
        !productModels.some((productModel) => model.includes(productModel))
      ) {
        // Checking if productModels is defined before calling some
        return false;
      }
      if (type && type === 'Loaded' && !productCategory.includes('loaded')) {
        return false;
      }
    }
    return true;
  });
  return filteredProducts;
};

export const filterProductsByYear = (products, year) => {
  // console.log(`[filterProducts][selections] >>> Year: ${year} >> Make: ${make} > Model: ${model} > Type: ${type}`);
  const filteredProducts = products?.filter((product) => {
    // console.log("[filterProducts][product] >>", product);
    if (product?.properties) {
      const productStartYear = parseInt(product.properties['Start Year'], 10);
      const productEndYear = parseInt(product.properties['End Year'], 10);

      let productModels = product.properties['Model'];
      productModels = productModels?.split('|'); // Adding conditional chaining here

      if (year && !(year >= productStartYear && year <= productEndYear)) {
        return false;
      }
    }
    return true;
  });
  return filteredProducts;
};

export const associateModelsToMakes = (filteredProducts) => {
  let carData = {};
  filteredProducts.forEach((product) => {
    let make = product.properties.Make;
    let models =
      typeof product.properties.Model === 'string' ? product.properties.Model.split('|') : [];
    models.forEach((model) => {
      if (carData[make]) {
        if (!carData[make].includes(model)) {
          carData[make].push(model);
        }
      } else {
        carData[make] = [model];
      }
    });
  });
  return carData;
};

export const extractUniqueMakes = (products) => {
  let makes = products?.map((product) => product?.properties?.Make);
  let uniqueMakes = [...new Set(makes)];
  return uniqueMakes;
};

export const extractUniqueModels = (products) => {
  let models = products?.map((product) => product?.properties?.Model);
  let uniqueModels = [...new Set(models)];
  uniqueModels = separateModelsWithLineBreak(uniqueModels);
  return uniqueModels;
};

export const separateModelsWithLineBreak = (array) => {
  const newArray = [];
  array.forEach((item) => {
    if (typeof item === 'string' && item.includes('|')) {
      // Check that item is defined and a string
      const splitItems = item.split('|');
      splitItems.forEach((splitItem) => {
        newArray.push(splitItem);
      });
    } else {
      newArray.push(item);
    }
  });
  return newArray;
};

export const getYears = (products) => {
  // get all product years
  const all_years = products.map((product) => {
    if (product?.properties) {
      const productStartYear = parseInt(product.properties['Start Year'], 10);
      const productEndYear = parseInt(product.properties['End Year'], 10);

      const allYearsBetween = [];
      for (let i = productStartYear; i <= productEndYear; i++) {
        allYearsBetween.push(i);
      }

      return allYearsBetween;
    }
  });

  // flatten all_years array and get unique values only and sort
  const all_years_flattened_sorted = all_years
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => b - a);

  return all_years_flattened_sorted;
};
