import Head from 'next/head';
import {
  baseUrl,
  GenerateOpenGraphPageMetaData,
  GenerateStaticPageMetaData
} from '@components/seo/MetaDataDefaults';

export const CollectionSeo = ({props}) => {
  // console.dir({CollectionSeo_props: props}, {depth: 2});

  const collection = props?.collection;
  // console.dir({CollectionSeo_collection: collection}, {depth: 2});

  const dbCollectionTitle =
    collection?.dbRow?.collection_page_title ?? props?.collectionPageInfo?.collection_page_title;
  const propsTitle = props?.title;
  const collectionTitle = collection?.title;
  const category = props?.category?.category;

  let seoTitle;
  if (dbCollectionTitle) {
    seoTitle = `${dbCollectionTitle} | Skar Audio`;
  } else if (propsTitle) {
    seoTitle = `${propsTitle} | Skar Audio`;
  } else if (collectionTitle) {
    seoTitle = `${collectionTitle} | Skar Audio`;
  } else if (category) {
    seoTitle = `${category} | Skar Audio`;
  }

  const seoDescription =
    collection?.dbRow?.collection_seo_description ??
    props?.collectionPageInfo?.collection_seo_description ??
    collection?.dbRow?.collection_description ??
    props?.collectionPageInfo?.collection_description;

  const imageUrl = `${baseUrl}${collection?.imageUrl}`;
  // console.log({seoTitle, seoDescription, imageUrl})

  let collectionHandle = props?.category?.handle ?? props?.collectionPageInfo?.handle;
  collectionHandle = collectionHandle?.replace('/', '');
  const isParentCategoryType = !props?.products || props?.products?.length === 0;
  const isCollectionType = !!(isParentCategoryType === false && props?.products?.length > 0 && collection?.slug);
  const canonicalUrl = isCollectionType
    ? `${baseUrl}${collection?.slug}`
    : `${baseUrl}/collections/${collectionHandle}`;
  // console.log({isParentCategoryType, isCollectionType, canonicalUrl})

  // console.log({canonicalUrl});

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta charSet='UTF-8'/>
      <meta name='description' content={seoDescription}/>
      {/*<link rel='canonical' href={canonicalUrl} />*/}

      <GenerateStaticPageMetaData/>
      <GenerateOpenGraphPageMetaData
        title={seoTitle}
        url={canonicalUrl}
        description={seoDescription}
        image={imageUrl}
        type={'og:product.group'}
      />
    </Head>
  );
};
