export function ProductQuantitySelector({quantity, setQuantity}) {
  const allowedNumbers = Array(1000)?.fill()?.map((v, i) => i + 1);
  const setQtyProtected = (qty) => {
    if (allowedNumbers?.includes(qty)) {
      setQuantity(qty);
    }
  };

  const handleChange = (event) => {
    const value = Number(event.target.value || '');
    // console.log({handleQtyChange: value});
    setQtyProtected(value);
  };

  const handleArrowChange = (event) => {
    const value = Number(event.target.value || '');
    if (event.key === 'ArrowUp') {
      setQtyProtected(value + 1);
    } else if (event.key === 'ArrowDown') {
      setQtyProtected(value - 1);
    }
  };

  return (
    <>
      <div className='flex mr-2 text-neutral-600'>
        <button
          aria-label='Decrease quantity by 1'
          className='font-medium text-2xl lg:px-4 lg:py-1 sm:px-2 sm:py-1 bg-black-900 hover:bg-gray-100 rounded-l border-2 border-r-0'
          style={{height: '46px', minWidth: '34px'}}
          onClick={(e) => setQtyProtected(quantity - 1)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={`h-5 w-5`}
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z'
              clipRule='evenodd'
            />
          </svg>
        </button>
        <label htmlFor='product-qty' className='visually-hidden'>
          Quantity
        </label>

        <input
          type='text'
          id='product-qty'
          name='product-qty'
          aria-label='Quantity'
          pattern='[0-9]*'
          onInput={(e) => handleChange(e)}
          onChange={(e) => handleChange(e)}
          value={quantity}
          onKeyDown={(e) => handleArrowChange(e)}
          className='bg-white border-2 lg:px-4 lg:py-2 sm:px-2 sm:py-1 text-center'
          style={{
            width: '80px',
            height: '46px',
            fontSize: '18px'
          }}
        />

        <button
          aria-label='Increase quantity by 1'
          className='lg:px-4 lg:py-1 sm:px-2 sm:py-1 bg-black-900 hover:bg-gray-100 rounded-r border-2 border-l-0'
          style={{height: '46px', minWidth: '34px'}}
          onClick={(e) => setQtyProtected(quantity + 1)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={`h-5 w-5`}
            viewBox='0 0 20 20'
            fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </div>
    </>
  );
}
